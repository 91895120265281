import { useSession } from "@core/hooks/useSession";
import { api } from "@core/services/nocd-api";
import { AxiosError } from "axios";
import { useQuery, UseQueryResult } from "react-query";
import slugify from "slugify";

export const getQueryKey = (accessToken?: string): [string, string] => [
  "subtypes",
  accessToken ?? "public",
];

export interface Subtype {
  id: string;
  description: string;
  imageUrl: string;
  title: string;
  selected: boolean;
  isSelected: boolean;
  slug: string;
}

export interface SubtypeDTO {
  id: string;
  title: string;
  description: string;
  selected: boolean;
  image_url: string;
}

export const transformSubtypeDto = ({
  id,
  title,
  description,
  selected,
  image_url,
}: SubtypeDTO): Subtype => {
  return {
    id,
    title,
    description,
    selected: !!selected,
    isSelected: !!selected,
    imageUrl: image_url,
    slug: slugify(title, { lower: true, strict: true }),
  };
};

export const fetchSubtypes = (
  accessToken?: string,
  signal?: AbortSignal
): Promise<Subtype[]> => {
  return api
    .get<SubtypeDTO[]>(
      "/v1/community/subtypes",
      accessToken
        ? {
            headers: { Authorization: accessToken },
            signal,
          }
        : { signal }
    )
    .then(({ data }) => data.map((subtype) => transformSubtypeDto(subtype)));
};

export const useSubtypes = (): UseQueryResult<Subtype[], AxiosError> => {
  const { data: session } = useSession();
  const accessToken = session?.accessToken;

  return useQuery(getQueryKey(accessToken), async ({ signal }) =>
    fetchSubtypes(accessToken, signal)
  );
};
